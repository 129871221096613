<template>
  <v-form>
    <div class="transparent elevation-0">
      <v-text-field
        v-model="currentPassword"
        name="Password"
        type="password"
        autocomplete="off"
        :label="$vuetify.lang.t('$vuetify.changePasswordForm.currentPassword')"
      />
      <v-text-field
        v-model="newPassword"
        name="newPassword"
        type="password"
        autocomplete="off"
        :label="$vuetify.lang.t('$vuetify.changePasswordForm.newPassword')"
      />
      <v-text-field
        v-model="confirmPassword"
        name="confirmPassword"
        type="password"
        autocomplete="off"
        :label="$vuetify.lang.t('$vuetify.general.confirmPassword')"
      />
      <span v-if="!error && !message" class="caption grey--text text--darken-1">
        {{ $vuetify.lang.t('$vuetify.registerForm.passwordInfo') }}
        <span class="font-weight-bold">
          6
        </span>
        {{ $vuetify.lang.t('$vuetify.registerForm.charactersLong') }}
      </span>
      <h5 v-if="error" class="error--text">
        <v-icon small class="mr-2 error--text">mdi-alert-circle</v-icon>
        {{ error.message }}
      </h5>
      <h5 v-if="message" class="success--text">
        <v-icon small class="mr-2 success--text"
          >mdi-check-circle-outline</v-icon
        >
        {{ message }}
      </h5>
      <v-card-actions>
        <v-layout row>
          <v-flex justify-center text-xs-right pl-0>
            <v-btn
              :disabled="
                !currentPassword ||
                  !newPassword ||
                  !confirmPassword ||
                  currentPassword.length < 6 ||
                  newPassword.length < 6 ||
                  newPassword !== confirmPassword
              "
              color="primary"
              :loading="loading.state === true"
              @click="changePasswordWithPassword()"
            >
              {{ $vuetify.lang.t('$vuetify.changePasswordForm.change') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </div>
  </v-form>
</template>
<script>
import { firebase } from '@firebase/app'

export default {
  name: 'ChangePasswordForm',
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      error: null,
      message: null,
      loading: false
    }
  },
  methods: {
    async changePasswordWithPassword() {
      const { currentUser } = firebase.auth()
      this.loading = true
      if (currentUser) {
        this.error = null
        try {
          const userCredentials = firebase.auth.EmailAuthProvider.credential(
            currentUser.email,
            this.currentPassword
          )
          await currentUser.reauthenticateWithCredential(userCredentials)
          currentUser.updatePassword(this.newPassword)
          this.message = 'Password updated successfully.'
          this.loading = false
          setTimeout(() => {
            this.message = null
          }, 1500)
        } catch (error) {
          this.error = error
          setTimeout(() => {
            this.error = null
          }, 2000)
          this.message = false
          this.loading = false
        }
      }
    }
  }
}
</script>
